<template>
  <iframe class="iframe-position" :src="src"></iframe>
</template>

<script>
import domain from '@/environment';
export default {
  data() {
    return {
      src: domain + '/api/libre/generate-pdf?id=' + this.$route?.params?.id
    };
  }
};
</script>

<style lang="scss" scoped>
.iframe-position {
  display: block;
  margin: auto;
  width: 750px;
  height: 92%;
  margin-top: 5px;
}
</style>
